.App {
  text-align: center;
  height: 100%;
  margin: 10px auto;
  padding: 5px;
  max-width: 560px;
  color: #444;
}

h1 {
  color: #666;
}

h2 {
  color: #333;
}

button {
  border: 1px solid #aaa;
  background-color: #eee;
  font-size: 1.1em;
  border-radius: 4px;
}

button:hover {
  background-color: rgb(161, 161, 161);
  color: white;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

@media screen and (min-width: 460px) {
  body,
  input,
  textarea {
    font-size: 1.1em;
  }
}
